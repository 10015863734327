import React, { useState } from 'react'

export default function BlogSearch(props) {
  const [searchValue, setSearchValue] = useState('')

  return (
    <input
      type="text"
      value={searchValue}
      placeholder="Search..."
      onChange={e => {
        setSearchValue(e.target.value)
        props.searchTextChanged(e.target.value)
      }}
    />
  )
}
